<template>
  <section id="benefits" class="border-bottom pt-2" :style="'background:'+$props.background+';color:'+$props.textColor">
    <h2 v-html="$props.title" class="display-4 text-center"></h2>
    <b-row>
      <b-col md="3" v-for="item in $props.items" :key="item.id" class="p-3">
        <b-card
          style="max-width: 30rem;border-radius: 1.0em;"
          class="m-2 border-0"
        >
          <b-card-text >
            <div class="text-center">
              <b-img :src="item.icon" height="80rem" alt="" class="mb-3" ></b-img>
            </div>
            <div class="title" v-html="item.title"></div>
            <div class="subtitle" v-html="item.description"></div>
          </b-card-text>
      </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
export default {
  name: 'BannerBenefitsComponent',
  props: {
    title: String,
    items: Array,
    background: String,
    textColor: String
  }
}
</script>
<style lang="scss" scoped>
  .title{
    font-size: 1.5rem;
    height: 2.5em;
    text-align: center;
    color:#333333;
    font-weight: bold;
  }
  .subtitle{
    font-size: 1.2em;
    text-align: center;
    color:#646464;
  }
</style>
