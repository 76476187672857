<template>
  <section class="banner_tip" :style="'background:'+$props.background+';color:'+$props.textColor">
    <b-row>
      <b-col cols="12" sm="12" md="8">
        <h3 class="title" v-html="$props.title"></h3>
        <h5 class="message" v-html="$props.message"></h5>
        <h6 class="author" v-html="$props.author"></h6>
        <b-img v-if="$props.icon2 && $props.icon2!=''" :src="$props.icon2" style="height: 30px" fluid alt="Responsive image"></b-img>
      </b-col>
      <b-col cols="12" sm="12" md="4" class="align-items-center align-content-center text-center">
        <b-img :src="$props.icon" style="height: 200px;" fluid alt="Responsive image"></b-img>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'BannerTipComponent',
  props: {
    title: String,
    message: String,
    icon: String,
    icon2: String,
    author: String,
    background: String,
    textColor: String
  }
}
</script>
<style lang="scss" scoped>
  @import "../../variables.scss";
  .banner_tip{
    padding:3rem;
    a{
      color: #1e934c;
    }
    .title{
      font-size: 1.9rem;
      font-weight: bold;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
    .message{
      font-size: 1.4rem;
      margin:auto;
    }
    .author{
      font-size: 1.3rem;
      margin-top: 2rem;
      font-style: italic;
    }
  }
</style>
