<template>
  <section class="loadingCustom" v-if="getLoading">
    <div>
      <img :src="$cdn + 'images/Loading-Systemico.gif'" alt="network. networks">
      <h1>Loading...</h1>
    </div>
  </section>
</template>

<script>

export default {
  name: 'loadingCustom',

  computed: {
    getLoading: function(){
      return this.$store.getters.getLoading;
    }
  }
}
</script>
