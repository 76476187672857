<template>
  <div id="contact" class="contact" :style="'background:'+$props.background+';color:'+$props.textColor">
    <h2>{{title}}</h2>
    <h4>{{subtitle}}</h4>
    <b-row class="mt-3">
      <b-col class="align-items-center pl-5 pr-5 pl-sm-0 pr-sm-0">
        <b-card class="border-0 bg-transparent" :style="'color:'+$props.textColor">
          <b-form @submit.stop.prevent>
            <!--FULL NAME -->
            <label for="text-password" class="label">{{translation[$props.lang].full_name}}</label>
            <b-form-input class="input" type="text" id="full_name" v-model="name"
                          aria-describedby="name-help-block"
                          :placeholder="translation[$props.lang].help_name"></b-form-input>
            <b-form-text id="name-help-block" class="help">
               {{translation[$props.lang].help_name}}
            </b-form-text>
            <!--PHONE NUMBER -->
            <label for="text-password" class="label">{{translation[$props.lang].phone}}</label>
            <PhoneInput />
            <!--PHONE EMAIL -->
            <label for="text-password" class="label">{{translation[$props.lang].email}}</label>
            <b-form-input class="input" type="email" id="email" v-model="email"
                          aria-describedby="name-help-block"
                          :placeholder="translation[$props.lang].help_email">
            </b-form-input>
            <b-form-text id="name-help-block" class="help">
              {{translation[$props.lang].help_email}}
            </b-form-text>
            <!-- NEED -->
            <label for="text-password" class="label">{{translation[$props.lang].request}}</label>
            <b-form-textarea
              id="txtmsg"
              v-model="message"
              class="input"
              :placeholder="translation[$props.lang].help_request"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-text id="name-help-block" class="help">
              {{translation[$props.lang].help_email}}
            </b-form-text>
            <div class="text-center pt-2">
              <ButtonComponent :text="translation[$props.lang].send" variant="secondary" @click="sendmsg()" :disabled="disabled" />
            </div>
            <div class="text-center pt-2" >
              <small :style="'color:'+$props.textColor">{{translation[$props.lang].help_policy}}</small>
            </div>
           </b-form>
        </b-card>
      </b-col>
      <b-col v-if="$props.withMap && $props.withMap=='1'" cols="12" md="6" class="p-2">
        <iframe class="container-fluid"
                height="100%"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15836.558647839292!2d-73.1197117!3d7.1098089!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e683fc4d14a2e99%3A0x4ca1f69d15b4532a!2sC.D.A.%20Island%20Mechanical%20Technical%20Review!5e0!3m2!1sen!2sco!4v1705092436025!5m2!1sen!2sco" style="border:2px solid #fefefe;margin:auto;"
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import ButtonComponent from "@/packages/ducksites.ui/components/buttons/ButtonComponent.vue";
import PhoneInput from "@/packages/ducksites.ui/components/inputs/PhoneInput.vue";
export default {
  name: "ContactAndMap",
  components: {
    PhoneInput,
    ButtonComponent,
  },
  props: {
    title: String,
    subtitle: String,
    background: String,
    textColor: String,
    withMap: { default: '', type: String },
    lang: { default: 'EN', type: String}
  },
  data: function () {
    return {
      send: {
        text: "Enviar Solicitud",
        class: "button--secundary",
        link: "",
      },
      disabled: false,
      name: '',
      phone: '',
      email: '',
      message: '',
      translation:{
        "ES": {
          "full_name": "Nombre completo",
          "phone": "Número de teléfono",
          "email": "Correo electrónico",
          "request": "Describe tu necesidad",
          "send": "Enviar solicitud",
          "help_email": "Escribe tu email de contacto.",
          "help_name": "Escribe tu nombre completo para ponernos en contacto.",
          "help_request": "Describe claramente tu necesidad, se concreto, si deseas puedes mencionar aspectos técnicos ó lo que consideres relevante.",
          "help_policy": "Al enviar este mensaje aceptas nuestros términos de uso y políticas de privacidad."
        },
        "EN": {
          "full_name": "Full name",
          "phone": "Phone number",
          "email": "Email",
          "request": "Detail your request",
          "send": "Send request",
          "help_email": "Type your contact email.",
          "help_name": "Type your full name to contact you.",
          "help_request": "Decribe clearly your need to help you.",
          "help_policy": "By sending this message you accept our terms of use and privacy policies."
        }
      }
    }
  },
  methods:{
    sendmsg: function(){
      this.disabled = true
      let formData = new FormData();
      formData.append("nombre_cliente", this.name);
      formData.append("email_cliente", this.email);
      formData.append("telefono_cliente", this.phone);
      formData.append("descripcion", 'Mensaje enviado desde web site Systemico: '+this.message);
      formData.append('api_client', clienteid);
      axios.post('https://suppmail.systemico.net/customer/cliente_ticket_crear',formData).then(response => {
        this.disabled = false
        let res = response.data
        if (res.CODIGO == 1) {
          this.$swal('Solicitud remita exitosamente, muy pronto nuestro equipo te contactara')
          this.name = ''
          this.phone = ''
          this.email = ''
          this.message = ''
          //this.$store.commit('setNotification', { state: true, title: 'Éxito', text: 'Recomendación enviada correctamente' });
          //this.close();
        }
        else if(res.CODIGO != 6) {
          //this.$store.commit('setNotification', { state: true, title: 'Error', text: response.MENSAJE });
        }
      });

      //this.$store.commit('setLoading', false);

    }
  }
}
</script>

<style lang="scss" scoped>
  .contact{
    padding: 3rem;
    @media (max-width: 768px){
      padding: 0;
    }
    h2{
      font-weight: bold;
    }
    h4{
      font-weight: 300;
      margin-left:20%;
      margin-right:20%;
    }
  }
  .label{
    font-size:16px;
    font-weight:bold;
  }
  .input{
    font-size:1rem;
    margin-bottom:1em;
  }
  .help{
    font-size:11px;
    display: none;
  }
</style>
